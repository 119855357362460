.MobileContainer .CONSOLE {
  position: absolute;
  z-index: 10000000;
  left: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.3); }

.DayActivityPopupAnimation-enter {
  background-color: rgba(0, 0, 0, 0) !important; }
  .DayActivityPopupAnimation-enter .DayActivity, .DayActivityPopupAnimation-enter .Comment {
    transform: translateY(100%); }

.DayActivityPopupAnimation-enter.DayActivityPopupAnimation-enter-active {
  background-color: rgba(0, 0, 0, 0.4) !important;
  transition: background-color 200ms ease-in; }
  .DayActivityPopupAnimation-enter.DayActivityPopupAnimation-enter-active .DayActivity, .DayActivityPopupAnimation-enter.DayActivityPopupAnimation-enter-active .Comment {
    transform: translateY(0%);
    transition: transform 200ms ease-in; }

.DayActivityPopupAnimation-leave {
  background-color: rgba(0, 0, 0, 0.4); }
  .DayActivityPopupAnimation-leave .DayActivity, .DayActivityPopupAnimation-leave .Comment {
    transform: translateY(0%); }

.DayActivityPopupAnimation-leave.DayActivityPopupAnimation-leave-active {
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 200ms ease-in; }
  .DayActivityPopupAnimation-leave.DayActivityPopupAnimation-leave-active .DayActivity, .DayActivityPopupAnimation-leave.DayActivityPopupAnimation-leave-active .Comment {
    transform: translateY(100%);
    transition: transform 200ms ease-in; }
