.MainScreen {
  padding-top: calc(44px + env(safe-area-inset-top));
  background-image: url("https://check.vkforms.ru/bg.jpg?1");
  background-size: 100vw;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .MainScreen__text {
    font-size: 36px;
    line-height: 38px;
    color: #FFFFFF;
    text-align: left;
    font-weight: 600;
    padding: 16px;
    box-sizing: border-box; }
  .MainScreen--green {
    background-image: url("https://check.vkforms.ru/bg_green.jpg"); }
  .MainScreen__day-list {
    padding-bottom: 45px;
    padding-left: 10px;
    padding-right: 10px;
    box-sizing: border-box; }
  .MainScreen__title {
    text-align: center;
    text-transform: uppercase;
    color: #FFF;
    font-size: 15px;
    line-height: 18px;
    font-weight: 900;
    margin-bottom: 10px;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif; }
  .MainScreen__day {
    text-align: center;
    text-transform: uppercase;
    color: #FFF;
    font-size: 52px;
    line-height: 52px;
    font-weight: 600; }
  .MainScreen__advice-list {
    text-align: center;
    padding-top: 41px; }
  .MainScreen__advice {
    background-color: #FFF;
    border-radius: 14px;
    padding: 10px 20px 17px 20px;
    display: inline-block;
    margin-right: 10px;
    flex-shrink: 0;
    max-width: 100%;
    box-sizing: border-box; }
  .MainScreen__advice--header {
    font-size: 24px;
    line-height: 38px;
    font-weight: 600;
    color: #222222; }
  .MainScreen__advice--body {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif;
    font-size: 15px;
    line-height: 20px;
    color: #76787A;
    white-space: normal; }
  .MainScreen__separator {
    margin-left: 16px;
    background-color: rgba(255, 255, 255, 0.24);
    width: calc(100% - 32px);
    height: 1px;
    margin-bottom: 16px; }
  .MainScreen__advice-wrapper {
    padding: 16px 16px 40px 16px;
    box-sizing: border-box;
    overflow: auto;
    display: flex;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch; }
  .MainScreen__tabs {
    padding: 8px 16px 0;
    box-sizing: border-box; }
    .MainScreen__tabs > button {
      -webkit-appearance: none;
      border-radius: 16px;
      height: 32px;
      color: #FFF;
      background-color: transparent;
      font-size: 16px;
      line-height: 19px;
      border: none;
      margin-right: 6px;
      padding: 0 12px;
      font-family: -apple-system, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif;
      outline: 0; }
      .MainScreen__tabs > button.active {
        background-color: rgba(255, 255, 255, 0.2); }
  .MainScreen__fake {
    width: 16px;
    flex-shrink: 0; }
