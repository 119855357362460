.DayState--commentable {
  padding-bottom: 56px; }

.DayState--confirmable {
  padding-bottom: 60px; }
  .DayState--confirmable .DayState__category:last-child .DayState__category-items {
    padding-bottom: 16px; }

.DayState__category {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.12); }
  .DayState__category:last-child {
    border-bottom: none; }

.DayState__category-name {
  font-size: 16px;
  line-height: 19px;
  font-weight: 500;
  color: #000;
  padding-top: 19px;
  padding-left: 14px; }

.DayState__category-items {
  padding: 11px 16px 20px;
  max-width: 100%;
  min-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  box-sizing: border-box; }

.DayState__item {
  display: inline-block;
  background-color: transparent;
  color: #528BCC;
  padding: 8px 14px 8px;
  font-size: 15px;
  line-height: 18px;
  font-weight: 500;
  margin-right: 8px;
  vertical-align: middle;
  border-radius: 10px;
  border-color: #528BCC;
  border-style: solid;
  border-width: 1px; }
  .DayState__item:last-child {
    margin-right: 0; }
  .DayState__item--active {
    color: #FFF;
    background-color: #528BCC;
    border-color: #528BCC; }

.DayState__comment {
  padding: 0 16px;
  margin-bottom: 15px;
  box-sizing: border-box;
  font-size: 15px;
  line-height: 21px; }

.DayState__comment-static {
  padding: 7px 14px 16px;
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.12); }

.DayState__comment-dynamic {
  padding: 10px 16px 0 16px; }

.DayState__comment-button {
  bottom: 65px;
  right: 16px;
  left: inherit;
  width: initial; }
  .DayState__comment-button button {
    width: inherit; }
  .DayState__comment-button .Button__content {
    padding: 0 0 !important; }
  .DayState__comment-button span {
    display: inline-block;
    vertical-align: middle; }
  .DayState__comment-button span:not(.DayState__pen) {
    line-height: 43px;
    margin-bottom: 1px; }

.DayState__pen {
  background: url("./pen.png") no-repeat center center/cover;
  width: 43px;
  height: 43px;
  display: inline-block;
  margin-left: -13px; }

.DayState__selected-items {
  padding: 0 16px 22px;
  color: #3F8AE0;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500; }

.DayState__empty {
  text-align: center;
  color: #CCC;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  padding: 0 16px 22px; }
