@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-ExtraBoldItalic.ttf") format("truetype");
  font-weight: 800;
  font-style: italic; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-DemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-DemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal; }

@font-face {
  font-family: 'TT Commons';
  src: url("./fonts/TTCommons-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal; }

body {
  margin: 0;
  padding: 0;
  color: #000;
  direction: ltr;
  font-size: 15px;
  font-family: TT Commons, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif;
  line-height: 15px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  background-image: url("https://check.vkforms.ru/bg.jpg?1");
  background-size: cover; }

.PanelHeader {
  display: none !important; }

.Panel__in {
  padding-top: 0 !important; }

input {
  margin: 0; }

button {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif !important; }

:root {
  --font-ios: TT Commons, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif;
  --font-android: TT Commons, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif;
  --font-common: TT Commons, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif; }

.plak-wrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%; }

.plak {
  background: url("./img/plak.png") no-repeat center center/cover;
  width: 72px;
  height: 72px;
  margin: auto; }

.plak-text {
  margin-top: 16px;
  font-size: 16px;
  color: #71757A;
  text-align: center;
  line-height: 20px; }

select {
  font-family: TT Commons, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif;
  outline: none;
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent; }

.Btn {
  width: 100%;
  background: #1F1819;
  font-size: 17px;
  color: #FFFFFF;
  text-align: center;
  font-weight: 500;
  padding: 12px 16px 11px 16px;
  border-radius: 10px;
  border: none;
  zoom: 1;
  cursor: pointer;
  white-space: nowrap;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
  font-family: TT Commons, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif;
  text-decoration: none;
  box-sizing: border-box;
  font-style: normal;
  margin: 0; }
  .Btn.mobile {
    font-size: 15px;
    font-weight: 500;
    border-radius: 8px; }
  .Btn:hover {
    background-color: #1F1819; }
  .Btn:active {
    background-color: #1F1819; }
  .Btn--primary {
    background: #1F1819;
    color: #FFFFFF; }
    .Btn--primary:hover {
      color: #FFFFFF;
      background: #1F1819; }
    .Btn--primary:active {
      color: #FFFFFF;
      background: #1F1819; }
    .Btn--primary:visited {
      color: #FFFFFF; }
  .Btn--disabled, .Btn:disabled {
    font-size: 15px;
    color: #71757A;
    background: #F0F3F7;
    border: 0.5px solid  #E7E8EC; }
    .Btn--disabled:hover, .Btn--disabled:active, .Btn:disabled:hover, .Btn:disabled:active {
      background: #F0F3F7;
      color: #71757A; }
  .Btn--light {
    background: rgba(255, 255, 255, 0.16);
    color: #FFFFFF; }
    .Btn--light:hover, .Btn--light:active {
      background: rgba(255, 255, 255, 0.16); }
    .Btn--light.mobile {
      color: #FFFFFF;
      background: rgba(255, 255, 255, 0.16); }
  .Btn--white {
    background: none;
    color: #2A5885; }
  .Btn.selected {
    background: #BBC9D8; }
  .Btn--wait {
    background: #5E81A8;
    color: rgba(255, 255, 255, 0); }
