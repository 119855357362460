.Calendar {
  background-color: #FFF; }
  .Calendar:last-child {
    padding-bottom: calc(env(safe-area-inset-bottom) + 49px); }
  .Calendar__table {
    background-color: #FFF;
    width: 100%;
    text-align: center; }
  .Calendar__day {
    padding: 10px 3px 6px;
    font-size: 19px;
    line-height: 19px; }
    .Calendar__day--not-same {
      opacity: 0; }
    .Calendar__day--red {
      color: #FF4A70; }
    .Calendar__day--today {
      background-color: rgba(0, 0, 0, 0.07); }
    .Calendar__day--ovul {
      color: #3F9AAD;
      position: relative; }
      .Calendar__day--ovul:before {
        position: absolute;
        width: 37px;
        height: 37px;
        border: 1px dashed #3F9AAD;
        border-radius: 50%;
        content: "";
        left: 50%;
        margin-left: -20px;
        top: -3px; }
    .Calendar__day--risk {
      color: #3F9AAD; }
  .Calendar__month-name {
    padding-bottom: 10px;
    text-align: center;
    font-size: 18px;
    line-height: 21px;
    color: #222222;
    font-weight: 500;
    padding-top: 23px; }
  .Calendar__separator {
    height: 0.5px;
    background-color: rgba(0, 0, 0, 0.12);
    margin: 16px auto 0; }
  .Calendar:last-child .Calendar__separator {
    background-color: transparent; }
