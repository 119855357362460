.FooterEx {
  position: fixed;
  z-index: 1000;
  height: 49px;
  left: 0;
  right: 0;
  bottom: calc(0px + env(safe-area-inset-bottom));
  display: flex;
  background: #FBFCFD;
  border-top: 0.5px solid rgba(0, 0, 0, 0.15); }
  .FooterEx:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 49px;
    height: 50px;
    background-color: #FFF;
    content: ""; }
  .FooterEx__icon {
    flex-grow: 1;
    text-align: center;
    height: 49px; }
    .FooterEx__icon--main {
      background-image: url("./main-on.svg");
      background-position: center center;
      background-repeat: no-repeat; }
    .FooterEx__icon--main-off {
      background-image: url("./main-off.svg");
      background-position: center center;
      background-repeat: no-repeat; }
    .FooterEx__icon--calendar {
      background-image: url("./calendar-on.svg");
      background-position: center center;
      background-repeat: no-repeat; }
    .FooterEx__icon--calendar-off {
      background-image: url("./calendar-off.svg");
      background-position: center center;
      background-repeat: no-repeat; }
    .FooterEx__icon--profile-off {
      background-image: url("./profile-off.svg");
      background-position: center center;
      background-repeat: no-repeat; }
    .FooterEx__icon--profile {
      background-image: url("./profile.svg");
      background-position: center center;
      background-repeat: no-repeat; }
