.DayActivity {
  box-sizing: border-box;
  background-color: #FFF;
  border-radius: 12px 12px 0 0;
  padding-bottom: 49px;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif; }
  .DayActivity--footer {
    padding-bottom: env(safe-area-inset-bottom); }
  .DayActivity__tab-list {
    padding: 0 4px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
  .DayActivity__tab {
    display: inline-block;
    font-size: 13px;
    line-height: 15px;
    text-transform: uppercase;
    padding: 17px 12px 13px;
    border-bottom: 2px solid transparent;
    text-align: center;
    color: #71757A;
    margin-bottom: -1px; }
    .DayActivity__tab--active {
      color: #71757A;
      border-bottom-color: #71757A; }
  .DayActivity__header {
    font-weight: 600;
    font-size: 26px;
    color: #222222;
    letter-spacing: 0;
    line-height: 28px;
    position: relative;
    padding: 23px 16px 18px; }
    .DayActivity__header--edit {
      padding-bottom: 0; }
  .DayActivity__close {
    position: absolute;
    right: 16px;
    top: 16px;
    width: 24px;
    height: 24px;
    background-color: #E8EAEE;
    border-radius: 50%;
    background-image: url("./close.svg");
    background-position: center center;
    background-repeat: no-repeat; }
  .DayActivity__edit-button {
    -webkit-appearance: none;
    border: none;
    background: transparent;
    cursor: pointer;
    text-align: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #5181B8;
    width: 100%;
    height: 49px;
    box-sizing: border-box;
    border-top: 0.5px solid rgba(0, 0, 0, 0.12);
    outline: 0; }
    .DayActivity__edit-button.blue {
      background-color: #5181B8;
      color: #FFF;
      border-top-color: #5181B8; }
  .DayActivity__ready-button-wrapper {
    bottom: calc(env(safe-area-inset-bottom) + 16px);
    padding: 0 16px; }
