.Comment {
  box-sizing: border-box;
  font-size: 15px;
  line-height: 21px;
  width: 100%;
  background: #FFF;
  border-radius: 14px 14px 0 0;
  position: relative;
  padding: 20px 16px calc(env(safe-area-inset-bottom) + 16px); }
  .Comment__title {
    font-weight: 600;
    font-size: 22px;
    color: #222222;
    padding-bottom: 14px; }
  .Comment__textarea {
    padding-bottom: 16px; }
  .Comment .Button__content {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif; }
  .Comment__close {
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    width: 24px;
    height: 24px;
    background: #E8EAEE url("./img/close.svg") no-repeat center;
    border-radius: 50%; }
