.SlideOption__wrapper {
  position: relative; }

.SlideOption__selected-value {
  font-size: 60px;
  color: #FFFFFF;
  text-align: center;
  font-weight: 600;
  position: relative;
  line-height: 100px; }

.SlideOption__invisible {
  opacity: 0;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  height: 120px; }

.SlideOption__days {
  font-size: 30px;
  color: #FFFFFF;
  font-weight: 600;
  text-align: center;
  margin-top: -15px; }

.SlideOption select {
  height: 120px;
  width: 100%; }
