.UserProfile {
  padding-bottom: calc(env(safe-area-inset-bottom) + 49px);
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Open Sans, Helvetica Neue, sans-serif; }
  .UserProfile__header {
    text-align: center;
    background-color: #FBFBFC;
    height: 44px;
    line-height: 44px;
    padding-top: env(safe-area-inset-top);
    box-sizing: content-box;
    font-size: 17px;
    font-weight: 500;
    border-bottom: 1px solid #D9D9D9; }
    .UserProfile__header.android {
      height: 56px;
      line-height: 56px; }
  .UserProfile__panel {
    background-color: #FFF;
    margin-top: 10px;
    margin-bottom: 10px;
    border-top: 1px solid rgba(0, 0, 0, 0.12); }
    .UserProfile__panel--no-access {
      margin-top: 10px;
      padding: 27px 16px 32px 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
      border-top: 1px solid rgba(0, 0, 0, 0.12); }
    .UserProfile__panel--friends {
      margin-top: 10px; }
  .UserProfile__add {
    font-size: 14px;
    color: #5181B8;
    font-weight: 500; }
  .UserProfile__selected-friends {
    padding: 0 16px; }
  .UserProfile__friends-header {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .UserProfile__friends-header-title {
    font-size: 17px;
    color: #000000;
    font-weight: 600; }
  .UserProfile__center {
    text-align: center; }
  .UserProfile__description {
    font-weight: 500;
    font-size: 17px;
    color: #71757A;
    text-align: center;
    line-height: 22px;
    padding-bottom: 17px; }
  .UserProfile__basic {
    padding: 18px 16px;
    font-size: 17px;
    line-height: 20px;
    font-weight: 500; }
  .UserProfile__select {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    appearance: none;
    margin: 0;
    padding: 0;
    opacity: 0;
    display: block;
    width: 100%;
    box-sizing: border-box; }
  .UserProfile__line {
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 16px 16px 12px 16px;
    box-sizing: border-box;
    justify-content: space-between; }
    .UserProfile__line > .left {
      font-size: 15px;
      line-height: 18px; }
    .UserProfile__line > .right {
      text-align: right;
      font-size: 15px;
      line-height: 18px;
      color: #909499; }
  .UserProfile__friends-list {
    overflow: auto;
    max-height: calc(100vh - env(safe-area-inset-top) - 95px);
    min-height: 117px;
    -webkit-overflow-scrolling: touch; }
    .UserProfile__friends-list .Checkbox:last-child {
      padding-bottom: 70px; }
    .UserProfile__friends-list .Checkbox__icon {
      margin: 0 16px 0 0; }
    .UserProfile__friends-list .Checkbox__container {
      align-items: center; }
  .UserProfile__friends-list-item {
    display: flex;
    align-items: center;
    width: 100%; }
  .UserProfile__friend {
    display: flex;
    align-items: center; }
    .UserProfile__friend--added {
      padding-bottom: 12px; }
      .UserProfile__friend--added:last-child {
        padding-bottom: 18px; }
  .UserProfile__shadow {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 2; }
  .UserProfile__no-friends {
    font-weight: 500;
    font-size: 17px;
    color: #71757A;
    text-align: center;
    line-height: 22px;
    padding: 46px 16px 16px; }
  .UserProfile__bottom-popup {
    box-sizing: border-box;
    max-height: calc(100vh - env(safe-area-inset-top) - 95px); }
