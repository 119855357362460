.DatePicker {
  margin: 0 16px; }
  .DatePicker__wrapper {
    position: relative; }
  .DatePicker__visible {
    background: rgba(255, 255, 255, 0.18);
    font-size: 25px;
    color: #FFFFFF;
    text-align: center;
    padding: 12px 16px 12px 16px;
    border-radius: 10px;
    line-height: 26px; }
    .DatePicker__visible span {
      font-size: 25px;
      color: #FFFFFF;
      text-align: center;
      font-weight: 600;
      line-height: 26px;
      vertical-align: sub; }
  .DatePicker__invisible {
    opacity: 0;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 56px; }
    .DatePicker__invisible input {
      height: 56px;
      width: 100%;
      display: block;
      -webkit-appearance: none;
      box-sizing: border-box; }
