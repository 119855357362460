.Timing {
  padding: 0 16px 16px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  box-sizing: border-box;
  background-image: url("https://check.vkforms.ru/bg.jpg?1");
  background-size: cover; }
  .Timing--welcome {
    background-image: url("./img/welcome.png"), linear-gradient(-130deg, #FFE4E0 0%, rgba(255, 207, 207, 0.85) 100%);
    background-repeat: no-repeat;
    background-size: auto 75%, cover;
    background-position: bottom; }
  .Timing--intro {
    background-image: url("./img/intro.png"), linear-gradient(-130deg, #FFE4E0 0%, rgba(255, 207, 207, 0.85) 100%);
    background-repeat: no-repeat;
    background-size: auto 62%, cover;
    background-position: bottom; }
  .Timing__title {
    font-size: 32px;
    color: #FFFFFF;
    text-align: center;
    line-height: 34px;
    width: 100%;
    font-weight: 700;
    padding-top: 90px; }
    .Timing__title--black {
      padding-top: 22%;
      color: #000000;
      padding-bottom: 10%; }
      @media screen and (max-device-width: 320px) {
        .Timing__title--black {
          font-size: 26px; } }
  .Timing__bottom-single {
    padding: 0 16px 50px 16px;
    box-sizing: border-box;
    width: 100%; }
    .Timing__bottom-single--shadowed {
      background-image: linear-gradient(-180deg, rgba(30, 22, 21, 0) 0%, rgba(30, 22, 21, 0.6) 97%);
      padding-top: 12%; }
  .Timing__bottom-double {
    padding: 0 16px 24px 16px;
    box-sizing: border-box;
    width: 100%; }
  .Timing__first-button {
    margin-bottom: 8px; }
  .Timing__center {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    min-height: 100px; }
  .Timing__button-next {
    font-weight: 500 !important;
    font-size: 17px !important;
    color: #452C28 !important;
    text-align: center !important;
    background: #FFFFFF !important; }
  .Timing .WaitDots {
    padding-top: 2px;
    vertical-align: text-bottom; }
