.CalendarScreen {
  background-image: url("https://check.vkforms.ru/bg.jpg?1");
  background-size: 100vw;
  padding-top: calc(44px + env(safe-area-inset-top));
  display: flex;
  flex-direction: column;
  box-sizing: border-box; }
  .CalendarScreen__week-day-list {
    flex-shrink: 0; }
  .CalendarScreen__scroll-wrapper {
    overflow: auto;
    -webkit-overflow-scrolling: touch; }
  .CalendarScreen--green {
    background-image: url("https://check.vkforms.ru/bg_green.jpg?1"); }
  .CalendarScreen__week-day-list {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 18px; }
  .CalendarScreen__week-day {
    font-size: 13px;
    line-height: 15px;
    color: rgba(255, 255, 255, 0.7); }
